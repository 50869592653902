// extracted by mini-css-extract-plugin
export default {"msgLiberisContainer":"nFUeaEV4Ntghw1ma1aUP","content":"wBJXAz5aWGb6KtSpbyn5","message":"jpuwt1Rsx6Rln4pNZhg7","msgContainer":"fEEvQ8TIc84BtiQlp7vg"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"msgLiberisContainer\":\"nFUeaEV4Ntghw1ma1aUP\",\"content\":\"wBJXAz5aWGb6KtSpbyn5\",\"message\":\"jpuwt1Rsx6Rln4pNZhg7\",\"msgContainer\":\"fEEvQ8TIc84BtiQlp7vg\"}";
        // 1728640261024
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  