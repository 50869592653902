// extracted by mini-css-extract-plugin
export default {"container":"UhmjkDSAl2eIytKz7Ru2","contract":"SZN0r1TXQivXzKBBPLRa","header":"cN0zXnTASlyfiiqXGuMb","left":"Q8KbpWew_JErOjdEV4aK","footer":"LXjr3gscsHMUeYvWB8R8"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"container\":\"UhmjkDSAl2eIytKz7Ru2\",\"contract\":\"SZN0r1TXQivXzKBBPLRa\",\"header\":\"cN0zXnTASlyfiiqXGuMb\",\"left\":\"Q8KbpWew_JErOjdEV4aK\",\"footer\":\"LXjr3gscsHMUeYvWB8R8\"}";
        // 1728640261938
        var cssReload = require("../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  